
textarea.InputText {
    /*z-index: -1;*/
    /*position: absolute;*/
    /*left: 0; top: 0;*/
    padding: 0;
    display: inline-block;
}

div.InputCopy {
    position: absolute;
    top: -24px;
    left: 300px;
    /*display: inline-block;*/
    visibility: hidden;
    padding: 0 5px 0 0;
}
