
div.content.conjugation div.columns {
    display: flex;
    width: 100%;
}

div.content.conjugation div.columns > div {
    min-width: 410px;
    /*flex-grow: 1;*/
}

div.content.conjugation div.columns > div.group1 {
    min-width: 380px;
}

div.content.conjugation div.columns > div > div.list {
    overflow: auto;
}

div.content.conjugation div.columns div.table {
    padding: 0 10px 15px 10px;
}

div.content.conjugation div.columns div.list {
    height: 300px;
    /*width: 330px;*/
    background-color: var(--card);
    transition: background-color 1s ease;
}

div.content.conjugation div.columns div.list div.snippet {
    padding: 8px 10px 7px 10px;
}

div.content.conjugation div.columns div.list div.snippet.active {
    background-color: var(--lessTrans);
    font-weight: 500;
}

div.content.conjugation table.simpleTense th,
div.content.conjugation table.simpleTense td {
    border-bottom: solid 1px var(--hairline);
    font-family: "Fira Sans Condensed", sans-serif;
    padding: 8px 10px 3px 0;
}

div.content.conjugation table.simpleTense td {
    font-size: 17px;
}

div.content.conjugation table.simpleTense th {
    text-align: left;
    font-weight: 500;
    color: var(--fgDim);
}

div.content.conjugation table.simpleTense tr:last-child td,
div.content.conjugation table.simpleTense tr:last-child th {
    border: none;
}

div.content.conjugation div.columns div.head {
    padding: 0 10px 0 10px;
    margin: 4px 0 8px 0;
    font-size: 17px;
}

div.content.conjugation div.columns h1 {
    padding: 0 10px 0 10px;
    font-size: 28px;
    margin: 10px 0 10px 0;
}

div.content.conjugation span.formula {
    background-color: var(--lessTrans);
    padding: 3px 5px 2px 5px;
    margin: -2px 0 0 12px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: 600;
    border-radius: 5px;
    color: var(--textHighlight);
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
}

div.content.conjugation span.formula span.x {
    font-weight: 300;
    color: var(--fgBright);
}

div.content.conjugation span.formula span {
    margin: 0 4px 0 4px;
}

div.content.conjugation div.searchForm {
    /*text-align: center;*/
    /*padding: 10px 10px 10px 380px;*/
    margin-top: 24px;
    display: flex;
}

div.content.conjugation div.searchForm div.stat {
    width: 380px;
    padding: 10px;
    color: var(--fgDim);
}

div.content.conjugation div.searchForm div.input {
    width: 410px;
    text-align: center;
}

div.content.conjugation div.searchForm div.input > div {
    margin-top: 8px;
    line-height: 18px;
}

div.content.conjugation div.searchForm div.input span.trans {
    font-size: 14px;
}

div.content.conjugation div.searchForm input {
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    height: 37px;
    padding: 4px 4px 3px 4px;
    font-family: inherit;
    outline: none;
    border: 1px solid var(--hairline);
    background-color: var(--card);
    color: var(--fgBright);
    /*transition: background-color 1s ease, color 1s ease;*/
}