
div.footer {
    clear: both;
    margin: 80px 20px 0 40px;
    display: flex;
    padding-bottom: 20px;
}

div.footer a.google {
    border-top: 1px solid var(--hairline);
    display: inline-block;
    padding: 4px 40px 0 0;
}

div.footer a.google div.mainLink {
    font-size: 15px;
    color: var(--fgBright);
}

div.footer a.google div.trans {
    font-size: 12px;
}

div.footer div.clipboard {
    margin-right: 40px;
}

div.footer div.clipboard textarea {
    position: absolute; bottom: 0; left: -210px;
    width: 200px; height: 100px;
}

div.footer div.clipboard button {
    background: none;
    color: inherit;
    border: none;
    border-top: 1px solid var(--hairline);
    border-radius: 0;
    padding: 4px 40px 0 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    text-align: left;
}

div.footer div.clipboard div.mainLink {
    font-size: 15px;
}

div.footer div.clipboard div {
    font-size: 12px;
}