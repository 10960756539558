
body.night div.nav div.checkbox div.label.checked { background-color: rgba(0,0,0,.25) }

div.nav {

    display: flex;
    flex-direction: column;

    // padding: 0 2px 0 4px;

    .home {
        // margin-left: 15px;
        text-align: center;
        padding-bottom: 8px;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, var(--midTrans) 100%);
        // border-bottom: 1px solid var(--microline);
        &.active {
            font-weight: bold;
        }
    }

    div.trans {
        font-size: 14px;
    }

    div.menu {

        /* div.contents {
            margin-left: 15px;
        } */

        div.filter {
            margin: 4px 0 8px 15px;
        }

        &.level1 {
            padding: 10px 2px 0 4px;
            margin-bottom: 2px;
            > div.contents {
                > div.item {
                    margin-bottom: 8px;
                }
            }
        }
        &.level2 {
            margin-bottom: 8px;
            > div.contents {
                > div.item {
                    margin-bottom: 6px;
                }
                font-size: 15px;
                div.trans {
                    font-size: 13px;
                }
            }
        }

        &.level3 {
            margin-bottom: 8px;
            > div.contents {
                > div.item {
                    margin-bottom: 8px;
                }
                font-size: 14px;
                div.trans {
                    font-size: 12px;
                }
            }
        }

        div.opener {
            width: 15px;
            padding-top: 1px;
            svg {
                width: 14px; height: 14px;
                polygon {
                    fill: var(--fgBright);
                    transition-duration: 0.2s;
                    transition-property: transform;
                }
            }
        }

        div.item {
            margin-left: 15px;
            > .title div.abc {
                display: inline-block;
                font-size: 12px;
                line-height: 12px;
                border: 1px solid var(--microline);
                border-radius: 4px;
                background-color: var(--bgColor);
                padding: 3px 4px 2px 4px;
                position: relative;
                top: -1px;
            }
        }

        /* &.nosublevel {
            div.item {
                margin-left: 0;
            }
        } */

        > div.contents, > div.filter {
            display: none;
        }

        > div.item.header {
            display: flex;
            margin-left: 0;
            cursor: pointer;
        }

        &.open {
            background-color: var(--navLayer50);
            > div.contents, > div.filter {
                display: block;
            }
        }

        &.active {
            > div.header {
                > div.opener {
                    svg polygon {fill: var(--linkHover);}
                }
                > div.title {
                    font-weight: 500;
                    color: var(--linkHover);
                }
                > a.title {
                    font-weight: 500;
                }
            }
            > div.contents > div.item.active {
                // color: var(--linkHover);
                font-weight: 500;
            }
        }

    }
}
