button {
    background: none;
    color: inherit;
    border: none;
    padding: 4px 16px 3px 16px;
    border-radius: 8px;
    font: inherit;
    outline: inherit;
    line-height: 24px;
    background-color: var(--bgButton);
}

button:disabled {
    opacity: .2;
}

button:hover {
    background-color: var(--bgButtonHover);
}

label {
    display: inline-block;
    vertical-align: baseline;
}

div.radio label {
    position: relative;
}

div.radio label > input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

div.radio label > input + span {
    display: inline-block;
    padding: 4px 18px 3px 18px;
    white-space: nowrap;
    background-color: var(--bgButton);
}

div.radio label:hover > input + span {
    display: inline-block;
    padding: 4px 18px 3px 18px;
    white-space: nowrap;
    background-color: var(--bgButtonHover);
}

div.radio label span.checked {
    background-color: var(--bgChecked);
    color: var(--fgChecked);
}

div.radio label:hover span.checked {
    background-color: var(--bgChecked);
}

div.radio label:first-child span {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

div.radio label:last-child span {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

div.radio.split label span {
    border-radius: 8px;
}

div.radio.split label {
    margin-right: 6px;
}

div.radio.split label:first-child {
    margin-bottom: 6px;
}

div.radio.split label:last-child {
    margin-right: 0;
}
