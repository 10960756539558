
span.formula {
    display: inline-block;
    line-height: 12px;
    vertical-align: text-bottom;
    background-color: var(--lessTrans);
    padding: 4px 6px 3px 6px;
    margin-right: 4px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: 600;
    border-radius: 6px;
    color: var(--fgDim);
}

span.formula:first-child {
    color: var(--textHighlight);
}

span.formula > span {
    margin: 0 2px 0 2px;
}

span.formula > span.x {
    font-weight: 300;
    color: var(--fgBright);
}
