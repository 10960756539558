
div.content.entry {
    padding-bottom: 2em;
}

div.content.entry header {
    margin: 40px 0 30px 40px;
}

div.content.entry header h2 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
}

div.content.entry > div.trans {
    margin: 6px 0 30px 40px;
    font-size: 20px;
}

div.content.entry div.iconX span.lemma {
    margin-right: 24px;
}

div.content.entry div.iconX span.icon {
    vertical-align: middle;
    /*display: inline-block;*/
    /*white-space: nowrap;*/
    /*background-color: red;*/
    /*margin: 0 0 -1px -12px;*/
}

div.content.entry div.iconX span.formulas {
    font-size: 16px;
    vertical-align: middle;
    display: inline-block;
    white-space: nowrap;
    margin-top: 2px;
    /*width: 200px;*/
    /*line-height: 24px;*/
}

div.content.entry div.iconX span.formulas > span.formula {
    /*display: flex;*/
    /*width: 90px;*/
    vertical-align: baseline;
    display: inline;
    line-height: 25px;
    background-color: var(--lessTrans);
    padding: 5px 6px 4px 6px;
    margin-right: 4px;
    text-transform: uppercase;
    font-family: Arial, sans-serif;
    font-weight: 600;
    border-radius: 8px;
    color: var(--fgDim);
}

div.content.entry div.iconX span.formulas > span.formula:first-child {
    /*font-weight: 600;*/
    color: var(--textHighlight);
}

div.content.entry div.iconX span.formulas > span.formula span.x {
    /*font-family: "Fira Sans", sans-serif;*/
    font-weight: 300;
    color: var(--fgBright);
    /*font-size: 32px;*/
}

div.content.entry div.iconX span.formulas > span.formula span {
    margin: 0 4px 0 4px;
}

div.content.entry div.searchForm div.trans span.sense:not(:last-child):after {
    content: '; ';
}

div.content.entry div.searchForm div.trans span:not(:last-child):after {
    content: ', ';
}

div.content.entry div.searchForm div.trans.details {
    margin-top: 6px;
    font-style: italic;
}

div.content.entry div.part {
    padding: 0 20px 0 40px;
}

div.content.entry div.tables { margin-bottom: -24px; }

div.content.entry div.tables > table {
    margin-bottom: 24px;
}

div.content.entry div.tables > table:not(:last-child) {
    margin-right: 40px;
}

div.content.entry div.tables > table {
    display: inline-block;
}

table {
    border-spacing: 0;
}

th, td {
    padding: 0;
}

div.content.entry table.stretch tr {
    height: 1px;
}
div.content.entry table.stretch td {
    height: inherit;
}

@supports (-moz-appearance:none) {
    div.content.entry table.stretch td {
        height: 100%;
    }
}

div.content.entry td.bracket { padding: 0 15px 0 15px; }
div.content.entry td.bracket > div {
    height: 100%;
}

div.content.entry td.bracket svg {
    display: block;
    height: 100%;
}

div.content.entry span.aux {
    /*background-color: var(--bgInvertMid);*/
    /*color: var(--bgColor);*/
    /*padding: 3px 6px 4px 6px;*/
    margin-right: 2px;
    /*font-size: 14px;*/
    /*font-weight: 500;*/
}

div.content.entry span.aux:not(:last-child):after {
    content: ", ";
}

/*span.aux:first-child { border-top-left-radius: 4px; border-bottom-left-radius: 4px; }*/
/*span.aux:last-child { border-top-right-radius: 4px; border-bottom-right-radius: 4px; }*/

div.content.entry .big {
    font-size: 20px;
}

div.content.entry td.big {
    /*padding-top: 8px;*/
}

div.content.entry div.family {
    float: right;
    padding: 18px 24px 18px 18px;
    margin: 0 0 20px 40px;
    border-radius: 12px;
    background-color: var(--trans);
    transition: background-color 1s ease;
}

div.content.entry div.family > div {
    margin: 8px 0 12px 0;
    line-height: 18px;
}
div.content.entry div.family > div.active {
    font-weight: 500;
}

div.content.entry div.family sub {
    vertical-align: baseline;
    border-radius: 4px;
    border: 1px solid var(--hairline);
    background-color: var(--trans);
    width: 18px;
    line-height: 16px;
    font-size: 13px;
    padding: 0;
    margin: 0 0 0 4px;
    text-align: center;
    display: inline-block;
}

div.content.entry div.family a sub {
    border-color: var(--linkBlue);
}

div.content.entry div.chains {
    padding-left: 40px;
    margin-bottom: -15px;
}

div.content.entry div.chains.part2 {
    -webkit-columns: 3 240px;
    -moz-columns: 3 240px;
    columns: 3 240px;
}

div.content.entry div.chains > a {
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 25px;
    color: var(--fgBright);
    /*transition: color 1s ease;*/
}

div.content.entry div.chains > a:hover {
    color: var(--linkHover);
}

div.content.entry div.chains > a > div {
    font-size: 18px;
    line-height: 23px;
    font-style: italic;
    margin-bottom: 2px;
}

div.content.entry div.chains > a > div.trans {
    /*background-color: var(--lightMarker);*/
    /*transition: background-color 1s ease;*/
    color: var(--transBlue);
    font-size: 15px;
    line-height: 20px;
    /*padding: 3px 8px 5px 8px;*/
    margin-bottom: 0;
    display: inline-block;
}

div.content.entry div.chains span.highlight {
    font-weight: 800;
}

div.content.entry div.hidden {
    margin: 80px 0 40px 40px;
    opacity: .2;
    /*display: inline-block;*/
    clear: both;
}