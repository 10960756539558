
div.content.grammar.nouns div.live table {

}

div.content.grammar.nouns div.live table tr.header td {
    border-bottom: 3px solid var(--microline);
}
div.content.grammar.nouns div.live table tr.singular td {
    border-bottom: 1px solid var(--microline);
}
div.content.grammar.nouns div.live table tr.singular td.cas,
div.content.grammar.nouns div.live table tr.singular td.prep,
div.content.grammar.nouns div.live table tr.plural td {
    border-bottom: 3px solid var(--microline);
}

div.content.grammar.nouns div.live table tr.singular td.cas {
    white-space: nowrap;
}

div.content.grammar.nouns div.live table tr td:not(:last-child) {
    padding-right: 30px;
}

div.content.grammar.nouns div.live table.form tr td:not(:last-child) {
    padding-right: 20px;
}

div.content.grammar.nouns div.live table td div.title {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 12px;
    text-transform: uppercase;
}

div.content.grammar.nouns div.live table td.header div.title {
    width: auto;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
}

div.content.grammar.nouns div.live table td div.title svg.icon.arrow {
    width: 16px;
    height: 16px;
}
div.content.grammar.nouns div.live table .header svg.icon.arrow path {
    stroke-width: 2px !important;
    stroke: var(--fgDim) !important;
}

div.content.grammar.nouns div.live table tr.singular td.header svg.icon.arrow path {
    stroke: var(--fgBright) !important;
}
div.content.grammar.nouns div.live table tr.plural td.header svg.icon.arrow path {
    stroke: var(--darkMagenta) !important;
}

div.content.grammar.nouns div.live table tr.header td {
    font-size: 24px;
}

div.content.grammar.nouns div.live table tr.header td svg.icon.male,
div.content.grammar.nouns div.live table tr.header td svg.icon.female {
    width: 10px;
    height: 20px;
    margin: 0 0 -1px 3px;
}

div.content.grammar.nouns div.live table td div.title > div {
    display: flex;
}

div.content.grammar.nouns div.live table td.header {
    text-align: right;
}

div.content.grammar.nouns div.live table td.header div.title > div {
    flex-direction: column;
    margin-right: 4px;
    /*background-color: blue;*/
}

div.content.grammar.nouns div.live table td div.title > div > div {
    margin: 0 2px;
}

div.content.grammar.nouns div.live table tr.header td div.title > div > svg:last-child {
    /*border: 1px solid red;*/
    margin-left: 6px;
}


div.content.grammar.nouns div.live table td div.down {
    margin-left: 4px;
}
div.content.grammar.nouns div.live table td div.right {
    margin-right: 4px;
}


div.content.grammar.nouns div.live table div.cas {

}

div.content.grammar.nouns div.live table div.cas div.trans {
    font-size: 14px;
}

/*  PREPS */

div.content.grammar.nouns div.live table td.prep {
    width: 260px;
}

div.content.grammar.nouns div.live table td.prep div.preps {
    display: flex;
    flex-wrap: wrap;
}

div.content.grammar.nouns div.live table div.symbol.prep span {
    font-size: 18px;
    line-height: 1.8;
}

div.content.grammar.nouns div.live table div.preps div.symbol.prep {
    margin-right: 4px;
}

div.content.grammar.nouns div.live table div.preps div.form.prep {
    margin: 3px 6px 3px 0;
}

div.content.grammar.nouns div.live table div.symbol.prep:not(:last-child) span:after {
    content: ", ";
    font-weight: 200;
}

div.content.grammar.nouns div.live table div.prep.symbol {
    height: auto;
}

div.content.grammar.nouns div.live table div.form.prep {
    border-radius: 6px;
    border: 1px solid transparent;
    background-color: var(--trans);
    cursor: pointer;
    padding: 5px 12px;
    min-width: 42px;
    text-align: center;
}

div.content.grammar.nouns div.live table div.form.prep > span {
    font-size: inherit;
}

div.content.grammar.nouns div.live table div.form.prep.clear {
    font-size: 32px;
    font-weight: 100;
    line-height: 42px;
    width: 46px;
}
div.content.grammar.nouns div.live table div.form.prep.clear.active {
    border-color: transparent;
    color: var(--linkHover);
}

div.content.grammar.nouns div.live table div.form.prep > div.trans {
    font-size: 13px;
}

div.content.grammar.nouns div.live table div.form.prep.active {
    background-color: var(--bgBW);
    border-color: var(--linkHover);
    cursor: text;
}

/* FORM */
div.content.grammar.nouns div.live table div.form {
    white-space: nowrap;
}

div.content.grammar.nouns div.live table div.form span {
    font-size: 20px;
}

body.day {
    --darkCyan: #1f6898;
    --darkMagenta: #a8469a;
}
body.night {
    --darkCyan: rgba(142, 230, 255, 0.8);
    --darkMagenta: rgba(239, 164, 238, 0.88);
}

div.content.grammar.nouns div.live table div.form span.flex {
    font-weight: 700;
}

div.content.grammar.nouns div.live table tr.singular div.form span.flex {
    /*color: var(--darkCyan);*/
}
div.content.grammar.nouns div.live table tr.plural div.form {
    color: var(--darkMagenta);
}

div.content.grammar.nouns div.live table div.form div.trans {
    font-size: 14px;
}
div.content.grammar.nouns div.live table div.form:not(:last-child) {
    margin-bottom: 4px;
}

/* SYMBOL */

div.content.grammar.nouns div.live table div.symbol span {
    font-size: 24px;
    line-height: 60px;
}

div.content.grammar.nouns div.live table tr.singular div.symbol span {
    /*font-weight: 500;*/
    /*color: var(--darkCyan);*/
}
div.content.grammar.nouns div.live table tr.plural div.symbol span {
    /*font-weight: 500;*/
    color: var(--darkMagenta);
}
div.content.grammar.nouns div.live table td.prep div.prep.symbol span {
    /*font-weight: 500;*/
    color: var(--fgBright);
}

div.content.grammar.nouns div.live table.form div.symbol span {
    opacity: .2;
}
div.content.grammar.nouns div.live table.form td.prep div.prep.symbol span {
    opacity: 1;
}

div.content.grammar.nouns div.live table div.symbol {
    height: 60px;
    white-space: nowrap;
}

div.content.grammar.nouns div.legend {
    float: right;
    margin-top: 30px;
    clear: both;
    font-size: 14px;
    white-space: nowrap;
}

div.content.grammar.nouns div.legend span.soften {
    display: inline-block;
    border: 1px solid var(--hairline);
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
    padding: 2px 5px 0 5px;
    margin: 0 3px;
}

div.content.grammar.nouns div.legend span.soften svg.arrow.icon {
    width: 13px; height: 13px; margin-bottom: -2px;
}
div.content.grammar.nouns div.legend span.soften svg.icon.arrow path {
    stroke-width: 1px !important;
    stroke: var(--fgBright);
}

div.content.grammar.nouns div.legend span.soft,
div.content.grammar.nouns div.live table span.soft {
    display: inline-block;
    border: 2px solid rgba(250, 195, 0, 0.55);
    border-radius: 5px;
    padding: 0 2px;
    margin: 0 0.5pt;
}

div.content.grammar.nouns div.legend span.soft,
div.content.grammar.nouns div.live table div.symbol span.soft {
    width: 16px;
    line-height: 24px;
    position: relative;
    top: -2px;
}
div.content.grammar.nouns div.legend span.soft {
    /*font-size: 16px;*/
    line-height: 20px;
    top: -1px;
}

div.content.grammar.nouns div.live table div.symbol span.flex:before {
    content: "-";
}

div.content.grammar.nouns div.live table div.symbol span.soft ~ span.flex:before {
    content: "";
}

div.content.grammar.nouns div.live table div.symbol span.soft:before {
    content: "-";
    /*margin-left: -14px;*/
    position: relative;
    top: 2px;
    left: -14px;
}

div.content.grammar.nouns div.legend span.soft:after,
div.content.grammar.nouns div.live table div.symbol span.soft:after {
    content: ",";
    position: relative;
    bottom: -13px;
    left: -14px;
    color: rgba(250, 195, 0, 0.55);
}

div.content.grammar.nouns div.legend span.soft:after {
    bottom: -14px;
    left: -2px;
    font-size: 23px;
}

div.content.grammar.nouns div.live table div.symbol span.flex:not(:last-child):after {
    content: ", ";
}
