div.content.grammar {

}

div.content.grammar h1 {
    margin: 20px 0 30px 40px;
    font-size: 32px;
    line-height: 45px;
}

div.content.grammar > header {
    margin: 20px 0 30px 40px;
}

div.content.grammar > header > h1 {
    margin: 0;
}

div.content.grammar h1.trans {
    font-size: 21px;
    line-height: 32px;
    font-weight: 400;
}

body.day div.content.grammar {
    --rulesType1: #a28dfa;
    --rulesType1stroke: #541bd9;
    --rulesType2: #e477ec;
    --rulesType23: #c18eec;
    --rulesType3: #facf5c;
    --selectedType1: rgba(232, 230, 223, 0.3);
    --selectedType2: rgb(255, 251, 203);
}
body.night div.content.grammar {
    --rulesType1: #9680cd;
    --rulesType1stroke: #a788ff;
    --rulesType2: #e477ec;
    --rulesType23: #c18eec;
    --rulesType3: rgba(255, 191, 17, 0.39);
    --selectedType1: rgba(232, 220, 136, 0.38);
    --selectedType2: rgb(255, 251, 203);
}

div.content.grammar div.striked {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

body.day div.content.grammar div.striked {
    background-image:
            url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width='100' height='100' preserveAspectRatio='none'><line x1='0' y1='100' x2='100' y2='0' stroke='black' stroke-width='2' stroke-opacity='1' vectorEffect='non-scaling-stroke' /></svg>");
}
body.night div.content.grammar div.striked {
    background-image:
            url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' width='100' height='100' preserveAspectRatio='none'><line x1='0' y1='100' x2='100' y2='0' stroke='white' stroke-width='2' stroke-opacity='1' vectorEffect='non-scaling-stroke' /></svg>");
}

div.content.grammar div.live {
    display: flex;
}

div.content.grammar div.live div.groups div.group.disabled {
    opacity: .2;
}

div.content.grammar div.live div.words span.sense:not(:last-child):after {
    content: "; "
}
div.content.grammar div.live div.words span.sense > span:not(:last-child):after {
    content: ", ";
}
