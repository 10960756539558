
div.mobile {

    div.train {

        h3 {
            // margin-bottom: 900px;
            text-align: center;
            margin-top: 0px;
        }

        div.answers {
            display: flex;
            justify-content: space-between;
            div.count {
                min-width: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 12px;
                text-align: center;
                
                div.number {
                    margin-top: 6px;
                    border-radius: 8px;
                    font-size: 24px;
                    padding: 4px 12px;
                    min-width: 40px;
                }
                &.correct {
                    div.number {
                        background-color: var(--bgCorrect);
                    }
                }
                &.incorrect {
                    div.number {
                        background-color: var(--bgError);
                    }
                }
                &.empty {
                    color: var(--mHairline);
                    div.number {
                        background-color: transparent;
                        border: 1px solid var(--mHairline);
                    }
                }
            }
        }

        div.sentence {

            display: flex;
            flex-wrap: wrap;
            align-items: baseline;
            justify-content: center;

            margin: 20px 0;

            div.part {
                display: flex;
                flex-direction: column;
                margin-bottom: 4px;
                padding: 3px;

                background-color: var(--bgBright);
                // border: 1px solid var(--mHairline);

                font-size: 20px;


                > div {
                    padding: 1px 4px 2px 4px;
                    white-space: nowrap;
                }

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    padding-left: 10px;
                }
                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding-right: 10px;
                }

                div.answer {
                    padding-left: 6px;
                    border-radius: 5px;
                    background-color: var(--bgBlue);
                    min-width: 90px;
                    &.correct {
                        background-color: var(--bgCorrect);
                    }
                    &.incorrect {
                        background-color: var(--bgError);
                    }
                }
            }

        }

        div.decoys {
            text-align: center;
            button {
                background-color: var(--bgBlue);
                min-width: 100px;
                font-size: 18px;
                margin: 0 14px 14px 0;
                padding: 6px 14px 7px 14px; 
            }
        }

        div.result {
            h3 {
                margin-top: 2em;
            }
            text-align: center;
        }

    }

}