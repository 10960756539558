
div.content.check {
    padding: 20px;
}

div.content.check table.words {

}

div.content.check table.words th {
    padding: 4px 12px 1px 8px;
    text-align: left;
}

div.content.check table.words td {
    padding: 4px 12px 1px 8px;
    border-bottom: 1px solid var(--hairline);
}

div.content.check table.words th.light,
div.content.check table.words td.light {
    background-color: var(--card);
}

div.content.check table.words td.number {
    text-align: right;
}