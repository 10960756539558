
div.content.test {
    padding-left: 20px;
}

div.content.test > h1 {

}

div.content.test p {
    max-width: 36em;
    line-height: 145%;
}

div.content.test a.longPress {
    -webkit-touch-callout: none;
    /*pointer-events: none;*/
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    hyphens: manual;
}