div.content.search {
    padding-bottom: 2em;
}

div.content.search div.serp {
    padding-left: 38px;
    margin-top: 1em;
    max-width: 1060px;
    display: flex;
    flex-wrap: wrap;
    /*align-content: flex-start;*/
    /*justify-content: space-between;*/
}

div.content.search div.serp a {
    color: var(--fgBright);
}

div.content.search div.serp > a {
    margin: 0 12px 12px 0;
}

div.content.search div.serp > a.card {
    /*display: inline-block;*/
    /*width: calc(33% - 15px);*/
    /*flex-grow: 1;*/
    padding: 16px;
    background-color: var(--card);
    /*border: 1px solid var(--hairline);*/
    border-radius: 16px;
    transition: background-color 1s ease;
}

div.content.search div.serp > a.wide {
    /*min-width: calc(50% - 12px);*/
    /*flex-grow: 1;*/
    padding: 14px;
    /*background-color: var(--trans);*/
    border: 1px solid var(--microline);
    border-radius: 16px;
    transition: background-color 1s ease;
}

div.content.search div.serp > a.wide:hover {
    border-color: var(--hairline);
}

div.content.search div.serp > a.card:hover {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
}
