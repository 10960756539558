
body.day div.lesson {
    --ring: rgba(0,0,0,.05);
    --segmentCorrect: #679C6E;
    --segmentError: #E8979D;
}
body.night div.lesson {
    --ring: rgba(255,255,255,.2);
    --segmentCorrect: #679C6E;
    --segmentError: #E8979D;
}

body.day div.content div.boring div.exercise div.default > span,
body.day div.content div.boring div.exercise div.default ol > li > span {
    background-image:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 64' width='10' height='64'><line x1='0' y1='0' x2='10' y2='0' stroke='black' opacity='0.08' stroke-width='3px' /></svg>");
}
body.night div.content div.boring div.exercise div.default > span,
body.night div.content div.boring div.exercise div.default ol > li > span {
    background-image:
        url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 64' width='10' height='64'><line x1='0' y1='0' x2='10' y2='0' stroke='white' opacity='0.4' /></svg>");
}

div.content.lesson, div.content.theory {

    div.lesson.boring {

        padding-left: 40px;
        padding-right: 210px;

        hr {
            border: none;
            height: 0;
            border-bottom: 1px solid var(--hairline);
        }

        div.number, span.number {
            display: inline-block;
            border: 1px solid var(--hairline);
            background-color: var(--card);
            text-align: center;
        }

        div.part {
            max-width: 47em;

            h2 span.number {
                border-radius: 10px;
                padding: 5px 16px 2px 16px;
                line-height: 1;
                margin-right: 6px;
                margin-left: -2px;
                min-width: 48px;
            }
        }

        ol {
            margin: 0;
        }

        div.col2 {
            column-count: 2;
            column-width: 250px;
        }

        div.cols {
            display: flex;
            &.gap {
                margin: 0 -20px;
                > * {
                    margin-left: 20px;
                    margin-right: 20px;
                }
            }
        }

        td {
            line-height: 1.5;
            vertical-align: top;
            div.qtext {
                top: 0;
            }
            div.qradio {
                white-space: nowrap;
            }
        }

        table.two {
            margin-top: 2em;
            th {
                font-weight: 500;
                padding-bottom: 10px;
            }
            td:first-child {
                padding: 9px 10px 0 0;
            }
            &.qa {
                td:first-child {
                    padding: 4px 12px 30px 0;
                }
                td:not(:last-child) {
                    padding-right: 12px;
                }
            }
        }


        div.cheatsheet {
            background-color: var(--card);
            border-radius: 15px;
            border: 1px solid var(--microline);
            display: inline-block;
            padding: 15px;

            table.grammar {
                th, td {
                    line-height: 1.4;
                    padding: 2px 12px 2px 2px;
                    text-align: left;
                    border-bottom: 1px solid var(--hairline);
                    vertical-align: top;
                }
                th {
                    font-weight: 300;
                    vertical-align: bottom;
                }
                tr:last-child td {
                    border-bottom: none;
                }
                td.bigger {
                    font-size: 18px;
                }
                td.smaller {
                    font-size: 14px;
                    padding-top: 4px;
                }
                td div.smaller {
                    font-size: 14px;
                }
            }
        }

        div.exercise {

            margin-bottom: 5em;

            div.number {
                border-radius: 8px;
                padding: 5px 12px 2px 12px;
                line-height: 1;
                margin-right: 6px;
                margin-top: -3px;
                margin-left: -2px;
                min-width: 48px;
                float: left;
            }

            p {
                line-height: 1.5;
            }

            div.default {
                display: inline-block;
            }

            div.default > span, div.default ol > li {
                line-height: 58px;
                background-position-y: 23px;
            }

            div.default ol > li > span {
                background-position-y: 23px;
            }

            div.inlineImage {
                float: right;
                margin: 20px 0 20px 20px;
                text-align: center;
                line-height: 1.4;
            }

            div.vocabulary {
                background-color: var(--card);
                border: 1px solid var(--microline);
                border-radius: 12px;
                display: inline-flex;
                flex-wrap: wrap;
                line-height: 1.5;
                padding: 9px;
                > div {
                    margin: 9px;
                }
            }
        }

        h1 {
            margin: 20px 0 30px 0;
            font-size: 32px;
            line-height: 45px;
        }

        div.task {
            font-weight: 500;
            line-height: 1.7;
            margin-bottom: 1em;
            svg.icon {
                margin-bottom: -2px;
            }
        }

        button.check {
            font-weight: 500;
            width: 100%;
            padding-top: 8px;
            padding-bottom: 7px;
            &:disabled {
                opacity: 0;
            }
        }

        div.qtext {
            position: relative;
            div.answer {
                position: absolute;
                display: flex;
                background-color: var(--bgButtonYellow);
                width: 100%;
                padding: 8px 6px 0px 6px;
                font-size: inherit;
                line-height: 24px;
                height: 32px;
                z-index: 1;
            }
            &.giveup {
                input {
                    visibility: hidden;
                }
            }
        }

        div.qradio {
            position: relative;
            div.answer {
                position: absolute;
                display: flex;
                flex-wrap: wrap;
                z-index: 1;
                div.label {
                    cursor: text;
                    background-color: var(--bgButtonYellow);
                    color: var(--fgButtonYellow);
                    text-decoration: line-through;
                    &.checked {
                        background-color: var(--bgChecked);
                        color: var(--fgChecked);
                        text-decoration: none;
                    }
                }
            }
            &.giveup {
                > div.label {
                    visibility: hidden;
                }
            }
        }

    }

    div.results {
        width: 200px;
        position: fixed;
        top: 0;
        right: 220px;
        background-color: var(--bgColorTransparent);

        h2 {
            margin-bottom: 0;
            text-align: center;
        }

        div.stats {
            div {
                padding-left: 2px;
                margin-bottom: 12px;
                border-bottom: 1px solid var(--hairline);
                span.number {
                    font-weight: 600;
                }
                &.correct {
                    span.number {
                        color: var(--fgCheckedGreen);
                    }
                }
                &.error {
                    span.number {
                        color: var(--fgCheckedRed);
                    }
                }
            }
            &.noanswers {
                text-align: center;
            }
        }

        div.buttons {
            text-align: center;
            margin-top: 30px;
            > div {
                margin-bottom: 30px;
            }
        }

        button.giveUp {
            background-color: var(--bgButtonYellow);
            color: var(--fgButtonYellow);
            &:hover {
                background-color: var(--bgButtonYellowHover);
            }
        }

        button.yes {
            margin-right: 5px;
            background-color: var(--bgButtonRed);
            color: var(--fgButtonRed);
            &:hover {
                background-color: var(--bgButtonRedHover);
            }
        }
        button.no {
            margin-left: 5px;
            background-color: var(--bgButton);
            color: var(--fgButton);
            &:hover {
                background-color: var(--bgButtonHover);
            }
        }
        
        
    }

    svg.donut circle.hole {
        fill: transparent;
    }
     
    svg.donut circle.ring {
        fill: transparent;
        stroke: var(--ring);
        stroke-width: 3;
    }
    
    svg.donut circle.segment {
        fill: transparent;
        stroke-width: 3;
        stroke-dasharray: 0 100;
        stroke-dashoffset: 50;
    }
     
    svg.donut circle.segment.correct {
        stroke: var(--segmentCorrect);
    }
    
    svg.donut circle.segment.error {
        stroke: var(--segmentError);
    }
    
    svg.donut text.percentage {
        fill: var(--fgBright);
        text-anchor: middle;
        font-size: 0.55em;
        font-weight: 800;
        line-height: 1;
        transform: translateY(0.33em);
    }

}