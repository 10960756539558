
div.content.stats {
    padding: 20px 40px;
}

div.content.stats table tr td {
    font-size: 20px;
    border-bottom: 1px solid var(--hairline);
    padding: 12px 0 0 20px;
}

div.content.stats table tr td:last-child {
    text-align: right;
}

div.content.stats table tr.subtotal td {
    font-weight: 500;
    padding-left: 0;
}

div.content.stats table tr.total td {
    font-weight: 600;
    padding-left: 0;
}

div.content.stats table tr.subtitle td {
    padding-left: 0;
    font-style: italic;
    border-bottom: none;
    /* font-size: 16px; */
}
