
div.content {
    flex-grow: 1;
    /*background-color: var(--bgColor);*/
    /*height: 100vh;*/
    min-height: calc(var(--vh, 1vh) * 100);
    /*overflow-x: scroll;*/
    padding-right: 220px;
}

div.content:focus {
    outline: none;
}

div.content div.searchForm {
    margin: 20px 0 40px 40px;
}

div.content input.search {
    /*transition: background-color 1s ease, color 1s ease;*/
    background-color: var(--bgColor);
    color: var(--fgBright);
    outline: none;
    border: none;
    /*border-bottom: 2px solid var(--hairline);*/
    font-family: inherit;
    font-size: 32px;
    line-height: 45px;
    height: 47px;
    font-weight: 500;
    padding: 0;
    /*width: 1px;*/
}

div.content textarea.search {
    /*transition: background-color 1s ease;*/
    /*background-color: silver;*/
    background-color: var(--bgColor);
    color: var(--fgBright);
    outline: none;
    border: none;
    font-family: inherit;
    font-size: 32px;
    line-height: 45px;
    height: 47px;
    font-weight: 500;
    /*padding: 0;*/
    word-wrap: break-word;
    /*word-wrap: normal;*/
    overflow: hidden;
    width: auto;
    max-width: 705px;
    resize: none;
}

/*div.content div.search {
    font-size: 32px;
    line-height: 45px;
    font-weight: 500;
    !*display: inline-block;*!
    word-wrap: break-word;
    !*word-wrap: normal;*!
    !*height: 47px;*!
    max-width: 700px;
    background-color: var(--lessTrans);
}*/

div.content textarea.search.ru {
    color: var(--transBlue);
    transition: background-color 1s ease, color 0s ease;
}

div.content textarea.search.unknown {
    color: var(--fgDim);
    transition: background-color 1s ease, color 0s ease;
}

div.content div.searchForm div.iconX {
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
}

div.content div.searchForm div.iconX button {
    margin-top: 0;
    padding: 3px 20px 0 20px;
    font-size: 28px;
    font-weight: 300;
}

/*div.content input.search:focus {
    outline: none;
    border: none;
}*/
