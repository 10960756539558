div.box {
    position: absolute;
    top: 20px;
    left: 20px;
    padding: 8px;
    z-index: 100;
}

div.box.href {
    cursor: pointer;
}

div.box > div {
    border-radius: 20px;
    padding: 24px;
    position: relative;
    /*background-color: var(--card);*/
    color: var(--fgBright);

    background-clip: padding-box;
    /*border: 10px solid rgba(0, 0, 0, .2);*/
    border: 9px solid var(--borderShadow);
    /*border: 15px solid var(--bgColor);
    border-top: 12px solid var(--bgColor);
    border-bottom: 30px solid var(--bgColor);*/
    /*background-color: #F0EFE9;*/
    background-color: var(--bgColorBitLighter);

    /*-webkit-box-shadow: 0px 8px 19px 0px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 8px 19px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 8px 19px 0px rgba(0,0,0,0.3);*/

}

button.x {
    position: absolute;
    top: 0; right: 0;
    font-family: inherit;
    font-size: 48px;
    font-weight: 100;
    line-height: 36px;
    width: 36px;
    height: 36px;
    color: var(--red);
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
}

div.box div.part {
    padding: 0;
}

div.box h1 {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 0 0;
}

div.box h2 {
    font-size: 15px;
    display: none;
}

div.box div.part h2 {
    margin-top: 0;
}

div.box table.transform th, div.box table.transform td {
    font-size: 13px;
    padding: 4px 5px 1px 0;
    border-bottom: 1px solid var(--hairline);
    transition: border-bottom-color 1s ease;
    vertical-align: bottom;
}

div.box table.transform tr:last-child th,
div.box table.transform tr:last-child td {
    border-bottom: none;
}

div.box div.trans {
    margin: 2px 0 6px 0;
    max-width: 22em;
    font-size: 14px;
}

div.box span.trans > span.sense:not(:last-child):after {
    content: '; ';
}

div.box span.trans span:not(:last-child):after {
    content: ', ';
}

div.goToPage {
    text-align: right;
    margin: 12px 0 0 0;
}

a.goToPage {
    background-color: var(--blue);
    color: var(--independentLight);
    display: inline-block;
    padding: 5px 8px 4px 8px;
    border-radius: 4px;
    font-size: 13px;
}
a.goToPage:hover {
    background-color: var(--blueHover);
    color: white;
}