
div.sidebar {
    position: fixed;
    right: 0; top: 0;
    /*height: 100vh;*/
    /*height: calc(var(--vh, 1vh) * 100);*/
    min-height: calc(var(--vh, 1vh) * 100);
    width: 200px;
    /*background-clip: padding-box;*/
    background-color: var(--side);
    /*transition: background-color 1s ease;*/
    /*border-left: 1px solid rgba(0, 0, 0, .07);*/
    display: flex;
    flex-direction: column;
    /*overflow-y: auto;*/
}

div.sidebar div.copyright {
    text-align: center;
    font-size: 12px;
    padding: 10px 10px 10px 5px;
    /*text-transform: uppercase;*/
}

div.sidebar div.gone {
    /*z-index: 107;*/
    font-size: 14px;
    text-align: center;
    padding: 6px 10px;
    margin-bottom: 4px;
    border-top: 2px dotted var(--hairline);
}

div.sidebar div.gone header {
}

div.sidebar div.gone div.there {
    display: flex;
    text-align: left;
    margin: 8px 0;
}
div.sidebar div.gone div.trans {
}
div.sidebar div.gone > div > a {
    display: block;
    border: 1px solid var(--hairline);
    border-radius: 7px;
    padding: 3px 3px 2px 3px;
}

div.sidebar .searchIcon {
    display: block;
    text-align: center;
    margin-bottom: 8px;
}

div.sidebar .searchIcon svg path {
    fill: var(--fgDim);
}

div.sidebar .searchIcon:hover svg path {
    fill: var(--fgBright);
}

div.sidebar button.moon {
    margin-bottom: 18px;
}
