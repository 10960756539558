
div.snippet div.head {
    margin-bottom: 4px;
    display: flex;
    align-items: baseline;
}

div.snippet div.head span.icon {
    margin: 3px 0 0 4px;
    align-self: flex-start;
}

div.snippet div.head span.formulas {
    font-size: 12px;
    margin: 4px 0 0 8px;
    align-self: flex-start;
    white-space: nowrap;
}

/*div.snippet div.head span.formula > span:not(:last-child):after {
    content: "-";
}*/

div.snippet.chain div.mainLink {
    font-size: 20px;
}

div.snippet div.trans span.sense:not(:last-child):after,
div.snippet span.trans span.sense:not(:last-child):after {
    content: '; ';
}

div.snippet div.trans span:not(:last-child):after,
div.snippet span.trans span:not(:last-child):after {
    content: ', ';
}

div.snippet div.head span.mainLink {
    font-size: 20px;
}

div.snippet span.trans:before {
    content: '\00a0\2014\20';
}

div.snippet div.tense,
div.snippet div.cas {
    font-size: 13px;
    font-style: italic;
    color: var(--fgBright);
    white-space: nowrap;
}

div.snippet div.tense {
    margin-bottom: 2px;
}

div.snippet div.tense.compound {
    /*display: inline-block;*/
    padding: 6px 0 0 0;
    /*border-top: 1px solid var(--hairline);*/
}

div.snippet span.ellip:after {
    content: "...";
}

div.snippet div.tense > span,
div.snippet div.cas > span{
    display: inline-block;
}
div.snippet div.tense > span.tense:not(:last-child):after,
div.snippet div.cas > span.cas:not(:last-child):after{
    content: ",\00a0";
}

div.snippet div.tense.compound > span.tense:not(:last-child):after {
    content: "\00a0\00a0";
}

div.snippet div.tense > span.person {
    width: 2.2em;
}

div.snippet div.cas > span.question {
    width: 3em;
}

div.snippet.short {
    cursor: pointer;
    line-height: 100%;
}

div.snippet.short span.mainLink {
    font-size: 18px;
    line-height: 22px;
}

div.snippet.short div.trans {
    font-size: 13px;
    line-height: 16px;
}

div.snippet.short div.trans span.trans:before {
    content: '';
}
