
body.day {
    --buttonIcon: rgba(0, 0, 0, .5);
    --clockFace: rgba(240, 240, 240, .8);
    --mark: rgba(0, 0, 0, .2);
    --hourHand: rgba(0, 0, 0, .47);
}
body.night {
    --buttonIcon: rgba(255, 255, 255, .6);
    --clockFace: rgba(0, 0, 0, .15);
    --mark: rgba(255, 255, 255, .4);
    /*--hourHand: rgba(170, 146, 63, 0.76);*/
    --hourHand: rgba(255, 255, 255, .33);
}

circle.face {
    fill: var(--clockFace);
}

svg.button circle.playPause {
    /*fill: rgba(240, 240, 240, .8);*/
    fill: var(--bgButton);
}

svg.button:hover circle.playPause {
    fill: var(--bgButtonHover);
}

svg.button polygon, svg.button rect {
    /*fill: #828282;*/
    fill: var(--buttonIcon);
}

line.mark {
    stroke-width: 0.5px; stroke: var(--mark);
}

line#hourhand {
    stroke-width: 22px; stroke: var(--hourHand);
    stroke-linecap: round;
}
line#minutehand {
    stroke-width: 33px; stroke: green;
    stroke-linecap: round;
    stroke-opacity: 0;
}
line#minutehandcolor {
    stroke-width: 11px; stroke: #ab5baf;
    stroke-linecap: round;
    stroke-opacity: 0.66;
}
line#minutehandshadow {
    stroke-width: 11px; stroke: white;
    stroke-linecap: round;
    stroke-opacity: .6;
}
line#secondhand {
    stroke-width: 0.5px; stroke: var(--mark);
}
