
@font-face {
    font-family: "Fira Sans Condensed";
    src: url("/assets/v9/fonts/FiraSansCondensed-Book.otf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: "Fira Sans Condensed";
    src: url("/assets/v9/fonts/FiraSansCondensed-Medium.otf") format("opentype");
    font-weight: 500;
}
@font-face {
    font-family: "Fira Sans Condensed";
    src: url("/assets/v9/fonts/FiraSansCondensed-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-Thin.otf") format("opentype");
    font-weight: 100;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-Light.otf") format("opentype");
    font-weight: 300;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-Regular.otf") format("opentype");
    font-weight: 400;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-Italic.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-Medium.otf") format("opentype");
    font-weight: 500;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-MediumItalic.otf") format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-Bold.otf") format("opentype");
    font-weight: 700;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-ExtraBold.otf") format("opentype");
    font-weight: 800;
}

@font-face {
    font-family: "Fira Sans";
    src: url("/assets/v9/fonts/FiraSans-ExtraBoldItalic.otf") format("opentype");
    font-weight: 800;
    font-style: italic;
}


* {
    box-sizing: border-box;
}

html {
    overflow-y: scroll;
}

body {
    /*transition: background-color 1s ease, color 1s ease;*/
    /*transform: rotateZ(360deg);*/
    /*backface-visibility: hidden;*/
    /*perspective: 1000;*/
    background-color: var(--bgColor);
    color: var(--fgBright);
    margin: 0; padding: 0;
    width: 100%;
    /*display: flex;*/
    flex-direction: column;
    font-family: "Fira Sans", Roboto, Arial, sans-serif;
    font-size: 16.5px;
    line-height: 1.5;
    background-attachment: fixed;
    background-size: cover;
}

h1, h2, h3, h4 { font-weight: 500 }

h2 {
    font-size: 27px;
}
h4 {
    font-size: 18.5px;
}

#root {
    display: flex;
    /*flex-grow: 1;*/
    /*flex-direction: row-reverse;*/
    min-height: 100vh;
}

html.hidden body {
    position: fixed;
    width: 100%;
}

html.hidden body div#root div.content div.col,
html.hidden body div#root div.sidebar,
html.hidden body div#root div.sidebar div.nav * {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
}

html body div#root div.content.home div.nameplate {
    display: none;
}
html.hidden body div#root div.content.home div.nameplate {
    display: block;
}

.touchObject {
    touch-action: none;
}

a {
    text-decoration: none;
    color: var(--linkBlue);
}

a:hover {
    color: var(--linkHover);
}

.longPress { white-space: nowrap; }

sup {
    font-size: 0.83em;
    vertical-align: super;
    line-height: 0;
}
sub {
    font-size: 0.83em;
    vertical-align: sub;
    line-height: 0;
}
