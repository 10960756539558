
div.loading {
    position: absolute;
    top: 0; left: 0;
    width: 100%; height: 100%;
    /*text-align: center;*/
    /*background-color: silver;*/
    display: flex;
    align-items: center;
    justify-content: center;
}

div.loading.local {
    position: inherit;
    height: inherit;
    display: block;
    text-align: center;
    padding: 10px;
}

div.loader {
    width: 64px; height: 64px;
    display: inline-block;
    margin-bottom: 5em;
    /*background-color: red;*/
    opacity: .16;
}

div.loading.local div.loader {
    width: 48px; height: 48px;
    display: inline-block;
    margin-bottom: 0;
    /*background-color: red;*/
    position: relative;
    opacity: .5;
}

svg.arc, svg.flower {
    position: absolute;
    width: 64px;
    height: 64px;
    fill: var(--fgBright);
}

div.loading.local svg.arc,
div.loading.local svg.flower {
    top: 0; left: 0;
    width: 36px;
    height: 36px;
    fill: var(--transBlue);
    /* fill: rgb(124, 205, 218); */
}

div.loading.trans svg.arc,
div.loading.trans svg.flower {
    fill: var(--transBlue);
}

svg.arc {
    animation: rotation 1.5s infinite;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
