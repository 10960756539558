div.content.chain {
    padding-bottom: 2em;
}

div.content.chain > header {
    margin: 40px 0 10px 40px;
}

div.content.chain > h1 {
    margin: 20px 0 30px 40px;
    font-size: 32px;
    line-height: 45px;
}

div.content.chain > h1 > a {
    color: var(--fgBright);
}

div.content.chain > h1.lemma {
    margin: 20px 0 0 40px;
    font-size: 32px;
}

div.content.chain > div.trans {
    margin: 6px 0 30px 40px;
    font-size: 20px;
}

div.content.chain > div.decompose {
    margin: 0 0 0 40px;
    display: flex;
    flex-wrap: wrap;
}

div.content.chain div.decompose a {
    color: var(--fgBright);
}

div.content.chain div.decompose > a {
    margin: 0 12px 12px 0;
}

div.content.chain div.decompose > a.card {
    /*display: inline-block;*/
    /*width: calc(33% - 15px);*/
    /*flex-grow: 1;*/
    padding: 16px;
    background-color: var(--card);
    /*border: 1px solid var(--hairline);*/
    border-radius: 16px;
    transition: background-color 1s ease;
}

div.content.chain div.decompose > a.wide {
    /*min-width: calc(50% - 12px);*/
    /*flex-grow: 1;*/
    /*width: 100%;*/
    padding: 14px;
    /*background-color: var(--trans);*/
    border: 1px solid var(--microline);
    border-radius: 16px;
    transition: background-color 1s ease;
}

div.content.chain div.decompose > a.wide:hover {
    border-color: var(--hairline);
}

div.content.chain div.decompose > a.card:hover {
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2);
}

div.content.chain div.media {
    margin: 0 20px 0 40px;
}

div.content.chain div.media header {
    margin-bottom: 10px;
}

div.content.chain div.media header > h2 {
    margin-bottom: 0;
}

div.content.chain div.media div.items {
    display: flex;
    flex-wrap: wrap;
}

div.content.chain div.media div.items div.item {
}

div.content.chain div.media div.items div.item > a {
    display: flex;
    max-width: 320px;
    margin-right: 20px;
    flex-direction: column;
}

div.content.chain div.media div.items div.item a img {
    width: 242px;
    height: 242px;
    border: 1px solid var(--hairline);
}
div.content.chain div.media div.items div.item a div.dummy {
    width: 242px;
    height: 242px;
    border: 1px solid var(--hairline);
}

div.content.chain div.media div.items div.item > a > div.header {
    margin-top: 8px;
    color: var(--fgBright);
    font-size: 18px;
    line-height: 24px;
}

div.content.chain div.media div.items div.item > a > div.header > span,
div.content.chain div.media div.items div.item > a > div.header > a {
    font-weight: 700;
}

div.content.chain div.hidden {
    margin: 80px 0 40px 40px;
    opacity: .2;
    clear: both;
}