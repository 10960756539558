
span.nowrap {
    white-space: nowrap;
}

div.mobile {

    hyphens: auto;

    background-color: var(--bg);

    h1, h2, h3 {
        font-weight: 400;
    }

    div.nav > div.content {
        padding: 12px 24px;
        min-height: unset;
    }

    div.content {

        .noborder { border: none !important; }

        h3 {
            margin: 2em 0 .5em 0;
        }

        div.links {
            margin-top: 8px;
        }

        a {
            color: var(--link);
        }

        a.crosslink {
            &::after {
                content: "\25ba";
                font-size: 67%;
                margin-left: 6px;
            }
        }

        .grammar {
            span.flex {
                font-weight: 700;
            }

            span.soft {
                display: inline-block;
                border: 2px solid var(--frame);
                border-radius: 5px;
                padding: 0 2px;
                margin: 0 1pt 0 0;
                width: 18px;
                height: 19px;
                line-height: 19px;
                position: relative;
                top: 2px;
            }

            span.soft:after {
                content: ",";
                font-weight: 900;
                position: relative;
                bottom: -5px;
                left: -3px;
                color: var(--frame);
            }

            span.soften {
                font-size: 14px;
                font-weight: 500;
                border: 2px solid var(--frame);
                border-radius: 5px;
                padding: 2px 6px 1px 6px;
                margin: 0 10px 12px 0;
                display: inline-block;
                white-space: nowrap;
                svg.icon.arrow {
                    width: 14px;
                    height: 14px;
                    path { stroke: var(--frame) }
                }
            }
            /* span.washed {
                color: var(--mSharpline);
            } */
        }


        ol {
            padding-left: 0px;
            list-style: none;
            counter-reset: c;
        }
        ol li {
            counter-increment: c;
            position: relative;
            margin-bottom: 1em;
        }
        ol li::before {
            content: counter(c) ". ";
            font-size: 11px;
            font-weight: 500;
            line-height: 25px;
            left: -16px;
            position: absolute;
        }

        .grammar.example {
            background-color: var(--bgDim);
            border-radius: 18px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 12px;
            padding: 10px 20px;
            transform: skew(-6deg);
        }
        .nb {
            border-radius: 18px;
            padding: 10px 20px;
            h4 { margin: 0; }
            border: 2px solid var(--mHairline);
            background-color: transparent;
        }
        div.grammar.example {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            > div {
                &:not(:last-child) {
                    margin-right: 16px;
                }
                margin-bottom: 10px;
            }
            padding-bottom: 0px;
        }
        li {
            div.example, p.example {
                margin-top: 6px;
            }
        }

        table.grammar {
            tr {
                vertical-align: bottom;
                &.top {
                    vertical-align: top;
                }
            }
            th {
                text-align: left;
                font-weight: 400;
                &.right {
                    text-align: right;
                }
            }
            th, td {
                border-bottom: 1px solid var(--mHairline);
                &:not(:last-child) {
                    padding-right: 12px;
                }
            }
            td {
                padding-top: 6px;
                br ~ span.trans {
                    font-size: 14px;
                    display: inline-block;
                    vertical-align: top;
                }
            }
            tr.group.title {
                > td {
                    border-bottom: none;
                    font-size: 14px;
                }
                + tr > td { padding-top: 0; }
            }
            &.large {
                width: 100%;
                td {
                    font-size: 18px;
                }
            }
            &.flexes {
                th {
                    &:not(:first-child) {
                        &::before {
                            content: "-";
                            color: transparent;
                        }
                    }
                }
                tr.part {
                    th, td { padding-top: 1em; }
                }

                td {
                    span.flex::before {
                        content: "-";
                        font-weight: 400;
                    }
                    span.soft ~ span.flex:before {
                        content: "";
                    }
                    svg.icon {
                        width: 12px;
                        height: 24px;
                    }
                }

            }
        }

        svg.icon.arrow {
            width: 16px;
            height: 16px;
            path {
                stroke-width: 2px;
                stroke: var(--mSharpline);
            }
            vertical-align: text-bottom;
            position: relative;
            top: -2px;
            margin-left: 4px;
        }

        div.tabs {
            margin-top: 2em;

            h3 {
                margin-top: 0;
            }
            p ~ h3, div ~ h3 {
                margin-top: 2em;
            }

            div.tab {
                display: none;
            }
            div.tab.active {
                display: block;
            }
            
            div.tab > div ~ div {
                margin-top: 2em;
            }
            // min-height: 550px;
        }
    }
}