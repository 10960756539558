div.msg {
     /*text-transform: capitalize;*/
     /*padding: 20px;*/
}

div.content.service {
 padding: 20px;
}

.trans { color: var(--transBlue) }
