
div.content.home {
    padding: 0;
    display: flex;
    min-height: unset;
}

body.day.home div.col.right {
    background-color: rgba(242,242,242,.7);
}
body.night.home div.col.right {
    background-color: rgba(70,70,70,.6);
}
body.night.home div.sidebar {
    background-color: rgba(70,70,70,.88);
}

div.content.home div.col.main {
    min-width: 620px;
    width: calc(100% - 560px - 9%);
    background-color: var(--bgColor);
    min-height: calc(100vh - 10px);
    padding: 20px 20px 40px 30px;
}

div.content.home div.col.main div.archive {
    border-top: 1px solid var(--hairline);
    padding-top: 6px;
}

@media screen and (min-width: 1601px) {
    div.content.home div.col.main {
        width: calc(100% - 560px - 15%);
    }
}

@media screen and (min-width: 1801px) {
    div.content.home div.col.main {
        width: calc(100% - 560px - 21%);
    }
}

@media screen and (min-width: 1921px) {
    div.content.home div.col.main {
        width: calc(100% - 560px - 25%);
    }
}

@media screen and (min-width: 2270px) {
    div.content.home div.col.main {
        width: calc(100% - 560px - 9%);
    }
}

@media screen and (min-width: 2500px) {
    div.content.home div.col.main {
        width: calc(100% - 560px - 17%);
    }
}

body.day.home div.content.home div.col.main {
    background-color: #f8f8f8;
}

div.content.home div.col.right {
    position: relative;
    width: 360px;
    min-height: calc(100vh - 10px);
    border-top-right-radius: 2px;
    /*text-align: center;*/
    /*padding: 10px;*/
}

div.content.home div.col.right::before {
    /* https://codepen.io/jonitrythall/pen/GJQBOp */
    content: '';
    /*margin: -20px;*/
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    filter: blur(20px);
    z-index: -1;
}

div.content.home div.clock {
    position: relative;
    text-align: center;
    padding: 20px;
}

div.content.home div.clock svg.button {
    position: absolute;
    top: 210px; right: 19px;
}
div.content.home div.clock svg.button circle {
    fill: var(--clockFace);
}

div.content.home div.clock div.dayTime {
    position: absolute;
    top: 10px; right: 20px;
    text-align: right;
}

div.content.home div.clock div.questionOfTime {
    position: absolute;
    top: 220px; left: 20px;
    text-align: left;
}

div.content.home div.clock div.questionOfTime div.trans {
    text-transform: lowercase;
}

div.content.home div.calendar {
    padding: 20px;
    text-align: center;
}

div.content.home div.calendar table {
    background-color: var(--clockFace);
    border-spacing: 0;
    margin: 0 auto;
    width: 320px;
}

div.content.home div.calendar table th > a {
    display: block;
    width: 44px;
    text-align: center;
    font-variant: small-caps;
    font-weight: normal;
    /*background-color: var(--trans);*/
    padding: 6px 0;
    /*border: 1px solid var(--bgColor);*/
}

div.content.home div.calendar table td {
    /*border: 1px solid rgba(0,0,0,0);*/
    /*position: relative;*/
}

div.content.home div.calendar table td div.date {
    /*border: 1px solid var(--bgColor);*/
    position: relative;
    width: 44px; height: 44px;
}

div.content.home div.calendar table td.past div.date {
    opacity: .33;
}

div.content.home div.calendar table td.today div.date {
    border: 1px solid var(--red);
}

div.content.home div.calendar table td div.date:hover {
    background-color: var(--trans);
}

div.content.home div.calendar table td.selected {
    background-color: var(--blue);
}

div.content.home div.calendar table td div.date div.number {
    position: absolute;
    right: 4px; bottom: 0;
    /*width: 40px; height: 40px;*/
}
div.content.home div.calendar table td div.date div.number:after {
    content: ".";
}

div.content.home div.calendar table th.weekend a,
div.content.home div.calendar table td.weekend div.date div.number {
    color: var(--red);
}

div.content.home div.calendar table td.selected div.date div.number {
    color: var(--bgBW);
}

div.content.home div.clock div.timeFAQ div.answer {
    height: 60px;
    text-align: left;
    /*font-size: 14px;*/
}

div.content.home div.calendar div.dayFAQ div.answer {
    padding: 4px 0;
    font-size: 18px;
}

div.content.home div.timeFAQ div.questions,
div.content.home div.dayFAQ div.questions {
    display: flex;
}

div.content.home div.dayFAQ div.questions {
    justify-content: space-between;
}

div.content.home div.timeFAQ div.questions div.question,
div.content.home div.dayFAQ div.questions div.question {
    padding: 3px 5px 1px 5px;
    cursor: pointer;
    border-radius: 6px;
}

div.content.home div.dayFAQ div.questions div.question {
    padding: 3px 12px 1px 12px;
    /*flex-grow: 1;*/
}

div.content.home div.timeFAQ div.questions div.question.active,
div.content.home div.dayFAQ div.questions div.question.active {
    background-color: rgba(171, 91, 175, 0.72);
    cursor: default;
}

div.content.home div.timeFAQ div.questions div.question.active > div,
div.content.home div.dayFAQ div.questions div.question.active > div {
    color: white;
}

div.content.home div.timeFAQ div.questions div.question > div,
div.content.home div.dayFAQ div.questions div.question > div {
    white-space: nowrap;
    text-align: left;
}

div.content.home div.timeFAQ div.questions div.question > div.trans,
div.content.home div.dayFAQ div.questions div.question > div.trans {
    font-size: 13px;
    text-transform: lowercase;
}

div.content.home div.nameplate {
    position: fixed;
    bottom: 3px; left: 3px;
    background-color: rgba(0, 0, 0, .8);
    color: rgba(255,255,255,.8);
    padding: 8px 12px;
    border-radius: 9px;
    font-size: 15px;
}
div.content.home div.nameplate div.title {
    font-weight: 500;
}
div.content.home div.nameplate div.trans {
    font-size: 12px;
}

div.content.home div.nameplate div.author {
    margin: 4px 0;
}

body.night div.content.home div.nameplate div.day {
    display: none;
}

body.day div.content.home div.nameplate div.night {
    display: none;
}