
button.moon {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-top: 20px;
}

button.moon:hover {
    background: none;
}
