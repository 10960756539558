
div.boring {

    div.qtext {
        
        display: inline-flex;
        flex-direction: column;

        input {
            color: var(--fgBright);
            padding: 9px 6px 0 6px;
            border: none;
            outline: none;
            background-color: var(--trans);
            /*background: none;*/
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            font-family: inherit;
            font-size: inherit;
            line-height: 24px;
            height: 32px;
        }

        div.resIcon {
            position: absolute;
            right: 0;
            top: 4px;
            width: 24px; height: 24px; line-height: 24px; font-size: 17px;
        }

        div.question {
            font-size: 14px;
            line-height: 14px;
            padding-top: 4px;
            height: 20px;
            text-align: center;
        }


    }

    div.qtext.correct {
        input {
            color: var(--fgButtonGreen);
            background-color: var(--bgButtonGreen);
        }
    }
    div.qtext.error {
        input {
            color: var(--fgButtonRed);
            background-color: var(--bgButtonRed);
        }
    }

    div.qradio {
        display: flex;
        align-items: flex-start;

        div.label {
            padding: 4px 18px 3px 18px;
            white-space: nowrap;
            background-color: var(--bgButton);
            cursor: pointer;
            &:first-child {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
            }
            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
            &:hover {
                background-color: var(--bgButtonHover);
            }

            svg {
                margin-bottom: -5px;
                g.figure {
                    fill: var(--fgDim) !important;
                }
            }
        }
        div.label.checked {
            color: var(--fgChecked);
            background-color: var(--bgChecked);
        }
    }

    div.qradio.error {
        div.label.checked {
            color: var(--fgCheckedRed);
            background-color: var(--bgChecked);
            svg {
                g.figure {
                    fill: var(--fgCheckedRed) !important;
                }
            }
        }
    }
    div.qradio.correct {
        div.label.checked {
            color: var(--fgCheckedGreen);
            background-color: var(--bgChecked);
            svg {
                g.figure {
                    fill: var(--fgCheckedGreen) !important;
                }
            }
        }
    }

    div.qradio.split {
        flex-wrap: wrap;
        div.label {
            margin-right: 6px; 
            margin-bottom: 6px;
            border-radius: 8px;    
        }
        div.label:last-child {
            margin-right: 0;
        }
    }
}

div.nav {
    div.checkbox {
        display: inline-flex;
        flex-direction: row;
        flex-grow: 0;
        width: auto;
        font-size: 12px;
        // border: 1px solid var(--lessTrans);
        border-radius: 5px;
        div.label {
            display: block;
            white-space: nowrap;
            background-color: var(--lessTrans);
            padding: 2px 10px 2px 8px;
            cursor: pointer;
            > span {
                color: var(--lessTrans);
                margin-right: 2px;
            }
            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;   
            }
            &.checked {
                color: var(--fgChecked);
                background-color: var(--bgChecked);
                > span {
                    color: var(--fgChecked);
                }
            }
        }
    }    
}