
div.content.grammar.verbs div.live {

}

div.content.grammar.verbs div.live div.table table {
    min-width: 560px;
    margin: 0 20px;
}

div.content.grammar.verbs div.live div.table div.symbol.flex.connected:before {
    content: "\00a0";
}
