.day {
    --bgColor: #f8f8f8;
    --bgColorTransparent: rgba(248,248,248,.75);
    --bgColorBitLighter: #fafafa;
    --borderShadow: rgba(60,60,60,.1);
    --fgBright: #333;
    --fgDim: #8e8e8e;
    --fgMid: #acacac;
    --hairline: rgba(0, 0, 0, .2);
    --microline: rgba(0, 0, 0, .1);
    --bgInvertMid: rgba(0, 0, 0, .33);
    --red: #d86b7c;
    --textHighlight: #d34a6e;
    --blue: #296cc7;
    --blueHover: #6b87b7;
    --linkBlue: #0060af;
    --linkHover: #FFB200;
    --trans: rgba(0, 0, 0, .03);
    --midTrans: rgba(153, 153, 153, .10);
    --lessTrans: rgba(0, 0, 0, .06);
    --lightMarker: #fffef3;
    --side: rgba(236, 237, 238, .8);
    --independentLight: rgba(255, 255, 255, .9);
    --transBlue: #0095ac;
    --card: #fefefe;
    --navText: #333;
    --navLink: #0060af;
    --fgBW: black;
    --bgBW: white;


    --bgButton: #EBEDF1;
    --bgButtonHover: #e3e7f0;

    --bgButtonRed: rgba(227, 138, 125, .1);
    --bgButtonRedHover: rgba(210, 103, 96, 0.12);
    --fgButtonRed: #691B0F;
    --bgButtonGreen: rgba(139, 227, 125, .1);
    --bgButtonGreenHover: rgba(141, 232, 127, 0.1);
    --fgButtonGreen: #1F610A;
    --bgButtonYellow: rgba(239, 214, 35, .14);
    --bgButtonYellowHover: rgba(233, 207, 8, .18);
    --fgButtonYellow: #97721A;


    --bgChecked: white;
    --fgChecked: #333;
    --fgCheckedRed: #BD2E18;
    --fgCheckedGreen: #38951B;


    --navLayer33: rgba(255, 255, 255, 0.33);
    --navLayer50: rgba(255, 255, 255, 0.5);
    --navLayer100: rgb(255, 255, 255);

    /* theory */
    --textRed: #9c1919;

}

.day.friday {
    --bgColor: #fcf8f4;
    --bgColorBitLighter: #fefbf6;
    --bgColorTransparent: rgba(252,248,244,.75);
    --fgDim: #8f8d8e;
    --lightMarker: #fffef3;
    /* --side: rgba(239, 234, 227, 1); */
    --side: rgba(248, 244, 240, .8);
    --independentLight: rgba(255, 255, 255, .9);
    --transBlue: #00879a;
    --card: #fffefa;

    --midTrans: rgba(160, 144, 125, 0.1);

    --navLayer33: rgba(255, 248, 242, 0.33);
    --navLayer50: rgba(255, 248, 242, 0.5);
    --navLayer100: rgb(255, 255, 255);
}

.night {
    --bgColor: #555555;
    --bgColorTransparent: rgba(85,85,85,.7);
    --bgColorBitLighter: #595959;
    --borderShadow: rgba(70,70,70,.7);
    --fgBright: #f2f2f2;
    --fgDim: #9e9e9e;
    --fgMid: #8c8c8c;
    --hairline: rgba(255, 255, 255, .33);
    --microline: rgba(255, 255, 255, .1);
    --bgInvertMid: rgba(255, 255, 255, .66);
    --red: #da6773;
    --textHighlight: #fdc0c2;
    --blue: #548dc6;
    --blueHover: #6b87b7;
    --linkBlue: #abc6f3;
    --linkHover: #e9cb8e;
    --trans: rgba(255, 255, 255, .08);
    --midTrans: rgba(255, 255, 255, .11);
    --lessTrans: rgba(255, 255, 255, .16);
    --lightMarker: #71695f;
    --side: rgba(0, 0, 0, .1);
    --independentLight: rgba(255, 255, 255, .9);
    --transBlue: #6feed6;
    --card: #474747;
    --navText: #f2f2f2;
    --navLink: #abc6f3;
    --fgBW: white;
    --bgBW: #404040;

    --bgButton: #525F76;
    --bgButtonHover: #5c6983;

    --bgButtonRed: #70534F;
    --bgButtonRedHover: #805a54;
    --fgButtonRed: #F9DDCD;
    --bgButtonGreen: #576652;
    --bgButtonGreenHover: #576652;
    --fgButtonGreen: #BCF8A9;
    --bgButtonYellow: #73623C;
    --bgButtonYellowHover: #7E6F42;
    --fgButtonYellow: #E7CB8B;

    --bgChecked: #434343;
    --fgChecked: #f2f2f2;
    --fgCheckedRed: #FFC7AD;
    --fgCheckedGreen: #BCF8A9;

    --navLayer16: rgba(60, 60, 60, 0.33);
    --navLayer33: rgba(60, 60, 60, 0.33);
    --navLayer50: rgba(60, 60, 60, 0.5);
    --navLayer40: rgba(60, 60, 60, 0.4);
    --navLayer60: rgba(60, 60, 60, .6);
    --navLayer100: rgb(60, 60, 60, 1);
    
    /* theory */
    --textRed: #e4a7a7;
}
