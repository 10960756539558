
div.content.grammar div.live div.wordsCol {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

div.content.grammar div.live div.wordsCol > header {
    margin: 4px 0 0 0;
    padding: 0 0 0 10px;
    font-size: 14px;
    align-self: stretch;
    color: var(--fgDim);
    border-bottom: 1px solid var(--hairline);
}

div.content.grammar div.live div.wordsContainer {
    overflow-y: scroll;
    flex-grow: 1;
    width: 100%;
    /*min-height: 300px;*/
}

div.content.grammar div.live div.words {
    height: 300px;
    padding-right: 8px;
}

div.content.grammar div.live div.wordsCol div.group {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
}

div.content.grammar div.live div.wordsCol div.word,
div.content.grammar div.live div.wordsCol div.clear {
    cursor: pointer;
    margin: 1px 0 4px 0;
    padding: 5px 12px 3px 10px;
    border-radius: 9px;
    border: 2px solid transparent;
    white-space: nowrap;
}

div.content.grammar div.live div.wordsCol div.clear {
    background-color: var(--trans);
}

div.content.grammar div.live div.wordsCol div.clear {
    padding-top: 10px;
    padding-bottom: 8px;
    display: inline-block;
}

div.content.grammar div.live div.wordsCol div.clear.active,
div.content.grammar div.live div.wordsCol div.word.active {
    background-color: var(--bgBW);
    border: 2px solid var(--linkHover);
}


div.content.grammar div.live div.words div.word {
    /*display: flex;*/
    /*flex-direction: column;*/
}

div.content.grammar div.live div.words div.word > div.lemma {
    white-space: nowrap;
}

div.content.grammar div.live div.words div.group header {
    margin: 0 0 0 10px;
    padding: 2px 0 0 0;
    font-size: 14px;
    border-top: 1px solid var(--hairline);
    align-self: stretch;
    font-weight: 500;
    color: var(--fgDim);
    letter-spacing: 0.5pt;
}
div.content.grammar div.live div.words div.group:first-child header {
    border-top: none;
}

div.content.grammar div.live div.words div.word div.trans,
div.content.grammar div.live div.words header div.trans,
div.content.grammar div.live div.words div.clear div.trans {
    font-size: 14px;
}

div.content.grammar div.live div.words div.word span.ellip:after {
    content: ",..";
}

div.content.grammar div.live div.words div.word.active,
div.content.grammar div.live div.words div.clear.active {
    cursor: default;
}

div.content.grammar div.live div.words div.word span.formulas {
    font-size: 11px;
    margin: 4px 0 0 8px;
    align-self: flex-start;
    white-space: nowrap;
}
div.content.grammar div.live div.words div.word span.formulas span.formula {
    line-height: 16px;
    padding: 3px 5px 3px 5px;
}