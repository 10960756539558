
svg g.primary {
    fill: var(--fgBright);
}

svg g.secondary {
    fill: var(--transBlue);
}
body.day svg g.secondary {
    fill-opacity: 0.7;
}

svg g.accent {
    fill: #E15839;
}
