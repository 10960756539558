
div.content.grammar.verbs div.live div.groups > div.group {
    margin-bottom: 16px;
}

div.content.grammar.verbs div.live div.groups > div.group > header {
    padding: 4px 6px 2px 6px;
    background-color: var(--hairline);
    color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

div.content.grammar.verbs div.live div.groups div.group div.trans {
    font-family: Tahoma, "Fira Sans", sans-serif;
    font-size: 12px;
}

div.content.grammar.verbs div.live div.ruleSet.person2,
div.content.grammar.verbs div.live div.ruleSet.person3,
div.content.grammar.verbs div.live div.ruleSet.person345 {
    border-top: 3px solid var(--hairline);
    margin-bottom: 12px;
}

div.content.grammar.verbs div.live div.ruleSet > header {
    padding-top: 3px;
}

div.content.grammar.verbs div.live div.ruleSet > header span.person {
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group {
    margin-right: 4px;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules {
    /*border: 1px solid lightskyblue;*/
    /*padding: 2px;*/
    display: flex;
    flex-wrap: wrap;
}

div.content.grammar.verbs div.live div.ruleSet div.rule {
    /*border: 1px solid hotpink;*/
    /*padding: 2px;*/
}

/*  */
div.content.grammar.verbs div.live div.ruleSet div.rule.addFlex {
    white-space: nowrap;
    /*background-color: red;*/
}
/* saprot(i) */
div.content.grammar.verbs div.live div.ruleSet div.rule.addFlex > div {
    display: inline-block;
    margin-right: 8px;
}
div.content.grammar.verbs div.live div.ruleSet div.rule.addFlex > div:last-child {
    margin-right: 0;
}
/*
div.content.grammar.verbs div.live div.ruleSet div.rule.addFlex > div:nth-child(2) {
    margin: 4px 0;
}
*/

div.content.grammar.verbs div.live div.ruleSet.person2 div.alternation div.alt,
div.content.grammar.verbs div.live div.ruleSet.person2 div.addFlex span.flex.flex,
div.content.grammar.verbs div.live div.ruleSet.person2 div.soften {
    border-color: var(--rulesType1);
}

div.content.grammar.verbs div.live div.ruleSet.person3 div.alternation div.alt,
div.content.grammar.verbs div.live div.ruleSet.person3 div.addFlex span.flex {
    border-color: var(--rulesType2);
}

div.content.grammar.verbs div.live div.ruleSet.person23 div.alternation div.alt {
    border-color: var(--rulesType23);
}

div.content.grammar.verbs div.live div.ruleSet.person345 div.alternation div.alt {
    border-color: var(--rulesType3);
}

div.content.grammar.verbs div.live div.ruleSet.person2 div.alternation.active div.alt,
div.content.grammar.verbs div.live div.ruleSet.person3 div.alternation.active div.alt,
div.content.grammar.verbs div.live div.ruleSet.person2 div.addFlex.active span.flex,
div.content.grammar.verbs div.live div.ruleSet.person3 div.addFlex.active span.flex,
div.content.grammar.verbs div.live div.ruleSet.person23 div.alternation.active div.alt {
    border-color: white;
}



div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.alternation,
div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.ruleLongA {
    white-space: nowrap;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.alternation > div {
    display: inline-block;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.alternation div.alt,
div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.addFlex span.flex {
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
    padding: 1px 4px 0 4px;
    min-width: 30px;
    text-align: center;
}
div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.addFlex span.flex {
    display: inline-block;
    margin-left: 2px;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.alternation svg.icon {
    width: 16px; height: 16px;
    vertical-align: middle;
    margin: -4px 4px 0 4px;
}
div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.alternation.active svg.icon path {
    stroke: white;
    stroke-opacity: 1;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.soften {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    font-size: 14px;
    justify-content: center;
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
}

div.content.grammar.verbs div.live div.ruleSet div.rule.group div.rules div.soften > div {
    flex-basis: 33%;
    text-align: center;
}

div.content.grammar.verbs div.live svg path {
    stroke-width: 2;
    stroke-opacity: .8;
}

div.content.grammar.verbs div.live div.rule.connected {
    border-radius: 10px;
    border: 2px solid transparent;
    padding: 2px;
    padding-left: 4px;
    margin-right: 4px;
    background-color: var(--trans);
}

div.content.grammar.verbs div.live div.ruleSet div.rule.connected.ruleLongA {
    border-color: var(--rulesType3);
    padding: 4px 6px 2px 4px;
}

/* CONNECTORS */

div.content.grammar.verbs div.live div.orangeMark {
    background-color: orange;
    border-radius: 16px;
    width: 16px; height: 8px;
    font-size: 1px; line-height: 1px;
    vertical-align: top;
    margin: 5px 4px 0 0;
    display: inline-block;
}

div.content.grammar.verbs div.live div.rule.ruleFlexP2.active {
    background-color: var(--rulesType1);
}
div.content.grammar.verbs div.live div.form.ruleFlexP2.active {
    border-color: var(--rulesType1);
}
div.content.grammar.verbs div.live svg.ruleFlexP2 path {
    stroke: var(--rulesType1) !important;
}

div.content.grammar.verbs div.live div.rule.ruleFlexP3.active {
    background-color: var(--rulesType2);
}
div.content.grammar.verbs div.live div.form.ruleFlexP3.active {
    border-color: var(--rulesType2);
}
div.content.grammar.verbs div.live svg.ruleFlexP3 path {
    stroke: var(--rulesType2) !important;
}

div.content.grammar.verbs div.live div.rule.ruleLongA.active {
    background-color: var(--rulesType3);
}
div.content.grammar.verbs div.live div.form.ruleLongA.active {
    border-color: var(--rulesType3);
}
div.content.grammar.verbs div.live svg.ruleLongA path {
    stroke: var(--rulesType3) !important;
}
