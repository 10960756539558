div.content.home div.days {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: flex-end;
}

div.content.book div.days {
    display: flex;
    flex-direction: row-reverse;
    min-height: calc(100% - 80px);
    justify-content: space-between;
}

div.content.home div.theDay {
    margin: 4em 40px 4em 0;
    width: 680px;
}

div.content.book div.theDay {
    width: 50%;
    /*border: 1px solid var(--pageBorder);*/
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

.day {
    --pageLeft: rgba(255, 255, 255, .74);
    --pageRight: rgba(255, 255, 255, .57);
}
.night {
    --pageLeft: rgba(0, 0, 0, .12);
    --pageRight: rgba(0, 0, 0, .1);
}

div.content.book div.theDay.left {
    /*background-color: var(--pageLeft);*/
    margin: 0;
    padding: 20px 25px 40px 30px;
}
div.content.book div.theDay.right {
    /*background-color: var(--pageRight);*/
    margin: 0;
    padding: 20px 30px 40px 25px;
}

body.day div.content.book div.theDay {
    background: linear-gradient(to right, var(--bgColor) 0%, var(--trans) 100%);
}

body.night div.content.book div.theDay {
    background: linear-gradient(to left, var(--bgColor) 0%, var(--trans) 100%);
}

div.content.home div.theDay div.date {
    font-size: 13px;
    text-transform: uppercase;
    color: var(--fgDim);
    border-top: 1px solid var(--hairline);
    padding-top: 3px;
    margin-bottom: 2em;
}
div.content.home div.theDay div.date {
    border: none;
}

div.content div.theDay > header,
div.content div.theDay div.media header {
    border: 1px solid var(--hairline);
    border-radius: 6px;
    font-size: 13px;
    line-height: 13px;
    text-transform: uppercase;
    display: inline-flex;
    padding: 2px 4px;
    margin-left: -2px;
}

div.content div.theDay > header {
    margin-top: 20px;
    margin-bottom: 8px;
}

div.content div.theDay div.subj {
    font-size: 25px;
    font-weight: 500;
    margin-top: 6px;
}

div.content div.theDay > header div,
div.content div.theDay div.media header div {
    margin: 2px;
    padding-top: 3px;
}

div.content div.theDay div.media {
    margin-top: 30px;
}

div.content div.theDay div.media div.items {
    display: flex;
    flex-wrap: wrap;
    margin: 10px 0;
}

div.content div.theDay div.media div.item {
    margin-right: 20px;
    max-width: 320px;
}

div.content div.theDay div.media div.item a {
    color: var(--fgBright);
}

div.content div.theDay div.media div.item div.screenshot {
    background-color: var(--card);
    width: 246px; height: 246px;
    padding: 2px;
    border: 1px solid var(--hairline);
    border-radius: 12px;
    margin-left: -2px;
}

div.content div.theDay div.media div.item img {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(85%);
    width: 240px; height: 240px;
    border-radius: 10px;
}

div.content div.theDay div.media div.item img:hover {
    filter: grayscale(33%);
}

div.content div.theDay div.media div.item div.header {
    font-size: 18px;
    margin-top: 6px;
}

div.content div.theDay div.media div.item div.header > span {
    font-weight: 700;
}

div.content div.theDay div.media header.wordsList {
    margin-bottom: 4px;
}

div.content div.theDay div.media header.wordsList div {
    /*display: inline-block;*/
}

div.content div.theDay div.media div.wordsList a:not(:last-child):after {
    content: ", ";
    color: var(--fgBright);
}

div.content.book div.pager {
    text-align: center;
    padding: 20px;
}

div.content.book div.pager button {
    margin: 0 10px;
}

div.content div.theDay object {
    border: 1px solid red;
}

div.content div.theDay div.doodle svg path {
    stroke-linecap:round;
    stroke-linejoin:round;
    fill:none;
    fill-rule:nonzero;
    stroke: var(--transBlue);
    stroke-width: 3px;
}
div.content div.theDay div.doodle.stroke2 svg path {
    stroke-width: 2px;
}
div.content div.theDay div.doodle svg g.lv path {
    stroke: var(--fgBright);
}
div.content div.theDay div.doodle a:hover > svg path {
    stroke: var(--linkHover);
}

div.content div.theDay div.doodle.right {
    float: right;
}

div.content div.theDay div.qimage {
    background-color: var(--card);
    padding: 2px;
    border: 1px solid var(--hairline);
    border-radius: 12px;
    margin-left: -2px;
    display: inline-flex;
}

div.content div.theDay h2 {
    font-size: 20px;
    margin-bottom: 0;
}

div.content div.theDay div.qimage a {
    display: inline-flex;
}

div.content div.theDay div.qimage img {
    border-radius: 10px;
}


div.content div.theDay div.qmessage {
    float: right;
    font-size: 13px;
    width: 320px;
    margin: 0 0 10px 15px;
    background-color: var(--card);
    border: 1px solid var(--hairline);
    border-radius: 20px;
    padding: 12px 8px 12px 10px;
}
div.content div.theDay div.qmessage table tr {
    vertical-align: top;
}
div.content div.theDay div.qmessage table td {
    padding: 6px 0 4px 0;
    border-bottom: 1px solid var(--hairline);
}
div.content div.theDay div.qmessage table tr td:first-child {
    padding-right: 10px;
}
div.content div.theDay div.qmessage table tr:last-child td {
    border-bottom: none;
}