
div.content.theory {

    div.lesson {

        div.theory {
            margin-bottom: 4em;
        }

        div.col2, div.col3, div.col4 {
            > div {
                break-inside: avoid-column;
                page-break-inside: avoid;
            }
        }

        div.col3 {
            column-count: 3;
        }
        div.col4 {
            column-count: 4;
            &.words {
                display: inline-block;
                > div {
                    margin-bottom: 1em;
                }
            }
        }

        div.words {
            display: flex;
            > div {
                &:not(:last-child) {
                    margin-right: 2em;
                }
                > div {
                    margin-bottom: 1em;
                }
            }
        }

        div.task {
            border-top: 1px solid var(--hairline);
            div.qsvg.icon {
                border-top: none;
                width: 32px;
                height: 32px;
                display: inline-block;
                vertical-align: text-top;
                margin-right: 5px;
            }
        }

        div.sheet {
            display: inline-block;
            padding: 6px 12px;
            background-color: var(--card);
            border: 1px solid var(--microline);
            border-radius: 5px;
        }

        table {
            th, td {
                &:not(:last-child) {
                    padding-right: 20px;
                }
            }
            tr.hr { th, td { border-top: 1px solid var(--hairline); } }
        }

        table.grammar {

            // background-color: var(--card);

            .male .part { color: var(--blue) }
            .female .part { color: var(--red) }
            .part.plural { color: var(--darkMagenta) }

            th {
                text-align: left;
                border-bottom: 1px solid var(--hairline);
                font-weight: 500;
                font-size: 20px;
                // line-height: 30px;
                svg.icon {
                    position: relative;
                    top: 2px;
                    margin-top: -5px;
                }
            }

            tr.smaller.headers th {
                font-size: 18px;
                &.inline.icon {
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
            }
            
            .wordparts {
                td {
                    vertical-align: baseline;
                    font-size: 20px;
                    line-height: 42px;
                    padding-right: 8px;
                    &.part.self {
                        font-size: 30px;
                        font-weight: 500;
                    }
                }
            }

            &.gender {
                th {
                    text-align: center;
                    div.qsvg {
                        width: 54px; height: 54px;
                        display: inline-block;
                    }
                    svg.icon {
                        width: 18px; height: 36px; margin-top: 0;
                    }
                }
                td {
                    font-size: 18px;
                    line-height: 32px;
                    &.wordparts {
                        a.longPress { color: var(--fgColor); }
                    }
                    div.word {
                        line-height: 24px;
                        margin: 10px 0;
                        div.trans {
                            font-size: 14px;
                        }
                    }
                }
                th.question {
                    vertical-align: middle;
                    border: none;
                }
            }

            &.cases {

                td {
                    padding-top: 10px;
                    padding-bottom: 16px;

                    div {
                        margin-bottom: 12px;
                        svg + span { font-weight: 500; }
                        max-width: 40em;
                    }

                    &.case {
                        padding-top: 6px;
                        font-size: 28px;
                        div.trans {
                            font-size: 18px;
                        }
                        div.question {
                            font-size: 20px;
                            margin-top: 12px;
                            font-weight: 500;
                            div.trans {
                                font-weight: 400;
                                font-size: 16.5px;
                            }
                        }
                    }

                }

                &.medium {
                    th, td {
                        font-size: 18px;
                    }
                    th {
                        font-weight: 400;
                    }
                }

            }
            
        }

        padding-left: 40px;

        h4 {
            margin-bottom: 0;
            + p {
                margin-top: 0;
            }
        }

        p {
            max-width: 50em;
            svg.icon {
                position: relative;
                top: 2px;
            }
        }

        span.term {
            font-weight: 500;
        }

        span.part {
            font-weight: 700;
            &.separate {
                &::before { content: "-"; font-weight: 400; }
                font-size: 18px;
            }
        }

        div.qradio::after {
            content: "\00A0";
            font-size: 20px;
            margin-left: 4px;
            width: 30px;
        }

        div.qradio.correct {
            div.label {
                background-color: var(--bgButtonGreen);
                &.checked {
                    background-color: var(--bgChecked);
                }
            }
            &::after {
                content: "\1F44D";
                font-size: 20px;
                margin-left: 4px;
            }
        }
        div.qradio.error {
            div.label {
                background-color: var(--bgButtonRed);
                &.checked {
                    background-color: var(--bgChecked);
                }
            }
            &::after {
                content: "\274C";
                font-size: 20px;
                margin-left: 4px;
            }
        }

        div.qsvg {
            svg {
                g#male {
                    fill: var(--blue);
                }
                g#female {
                    fill: var(--red);
                }
                g.strokes {
                    stroke: var(--fgBW);
                    stroke-width: 0.5px;
                }
                .fgBright { fill: var(--fgBright); }
                .textRed { fill: var(--textRed); }
            }
        }

        svg.icon.arrow {
            width: 13px;
            height: 13px;
            position: relative;
            top: 1px;
            path { stroke: var(--fgBright) !important; }
        }
        div.trans svg.icon.arrow path { stroke: var(--transBlue) !important; }

    }
}